import React from "react"
import "./style.scss"
import Widget from ".."

interface IWidgetGroup {
  region: Array<any>
  className?: string
}

const WidgetGroup: React.FC<IWidgetGroup> = props => {
  return (
    <section className={`widget-group ${props.className}`}>
      {props.region.map((widgetData, index) => (
        <div className="widget">
          <Widget {...widgetData} key={index} />
        </div>
      ))}
    </section>
  )
}

export default WidgetGroup
