import React, { useState } from "react"
import { Link } from "gatsby"
import CTA from "../../../elements/CTA"
import ArrowRight from "../../../components/SVGs/arrow-right.inline.svg"
import "../../../layouts/Primary/styles/skin/templates/_campaign.scss"
import ModalVideo from "../../../elements/ModalVideo"
import { GatsbyImage } from 'gatsby-plugin-image'

interface ICampaignLanding {
  heading: string
  subheading: string
  button: any
  image?: {
    fluid: any
    title?: string
  }
}

const CampaignLanding: React.FC<ICampaignLanding> = ({ heading, subheading, button, image }) => {
  const [modalShow, setModalShow] = useState(false)

  const anchorHandle = (e: any) => {
    e.preventDefault()
    const campaignRolesSection = document.querySelector("#campaign-roles")

    if (campaignRolesSection) {
      window.scroll(0, campaignRolesSection.offsetTop)
    }
  }

  const openModal = (e: any) => {
    e.preventDefault()
    setModalShow(true)
  }

  const hideModal = () => {
    setModalShow(false)
  }

  let heroButton = null

  if (button) {
    if (button.type === "Internal Link" && button.url?.includes("#campaign-roles")) {
      heroButton = (
        <Link className="btn btn-light has-addon" to={button.url} onClick={anchorHandle}>
          {button.label}
          <span className="btn-addon bg-primary">
            <ArrowRight className="icon-sprite" />
          </span>
        </Link>
      )
    } else if (button.type === "External Link") {
      heroButton = (
        <button className="btn btn-light has-addon" onClick={openModal}>
          {button.label}
          <span className="btn-addon bg-primary">
            <ArrowRight className="icon-sprite" />
          </span>
        </button>
      )
    } else {
      heroButton = <CTA {...button} />
    }
  }

  return (
    <section className="hero-landing">
      <img
        className="hero-landing-image"
        srcSet={image?.fluid?.images?.fallback?.srcSet}
        alt={image?.title || ""}
        loading="lazy"
      />
      <div className="container hero-landing-content fade-in-up">
        <div className="col-lg-12">
          <p className="sub-heading">
            <span>{subheading}</span>
          </p>
          <h1 className="hero-heading">
            <span>{heading}</span>
          </h1>
          {heroButton}
        </div>
      </div>
      <ModalVideo show={modalShow} onHide={hideModal} video={button?.url} />
    </section>
  )
}

export default CampaignLanding
