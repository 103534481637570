import React from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import SEO from "../layouts/Primary/seo"
// global styles importation removed!!!
import "../layouts/Primary/styles/skin/templates/_campaign.scss"
// @ts-ignore
import loadScripts from "../utils/scripts"

import CampaignLanding from "../components/campaign/landing"
import AboutYellowContent from "../components/about-content"
import HomePageLogos from "../components/home-logos"
import QuoteBanner from "../components/quote-banner"
import CentralImage from "../components/central-image"
import Widget from "../components/widgets"
import CampaignFooterCTA from "../components/campaign/footer-cta"
import Footer from "../layouts/Primary/Footer"
import Header from "../layouts/Primary/Header"
import CampaignHeader from "../components/campaign/header"
import LatestRoles from "../components/campaign/latest-roles"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"
import WidgetGroup from "../components/widgets/widgets-group"

export interface ICampaignPage {
  id: string
  text: string
  applyUrl: string
  title: string
  showNavbar?: boolean
  ogImage?: {
    file?: {
      url: string
    }
  }
  seoTitle: string
  seoDescription: string
  heroHeading: string
  heroTagline: string
  heroImage: {
    fluid: any
  }
  heroButton: any

  yellowSectionHeading: string
  yellowSectionContent: any

  quoteAuthor: string
  quoteContent: any
  quoteImage: {
    fluid: any
  }

  centralImage: {
    fluid: any
  }

  awardsSectionTitle: string
  awardsImages: any
  latestRolesLists: {
    title: string
    teamName: string
    image: {
      fixed: any
      title: string
    }
    button: any
    content: any
    searchParam: string
  }[]
  middleRegion: Array<any>
  bottomRegion: Array<any>

  joinUsHeading: string
  joinUsContent: any
  joinUsButtons: Array<any>
}

export interface IJobListingProps {
  allLever: {
    nodes: Array<any>
  }
  contentfulCareersCampaign: ICampaignPage
}

interface ICareersHomePageContext {
  id: string
  slug: string
  locale: string
}

const CampaignPage: React.FC<PageProps<IJobListingProps, ICareersHomePageContext>> = ({ data, path, pageContext }) => {
  React.useEffect(() => {
    loadScripts()
    window.scrollBy(0, 1)
    window.scrollBy(0, -1)
  }, [])

  const locale = pageContext.locale || "en-US"
  const image = data.contentfulCareersCampaign?.ogImage?.file?.url
  const showCentralImg = data.contentfulCareersCampaign.centralImage ? true : false;
  let imageForSeo = ""
  const showNavbar = data.contentfulCareersCampaign?.showNavbar ? true : false;
  if (image) {
    imageForSeo = image.startsWith("//") ? `https:${image}` : image
  }
  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      {showNavbar && <Header />}
      <SEO
        title={data.contentfulCareersCampaign.seoTitle || data.contentfulCareersCampaign.title}
        description={data.contentfulCareersCampaign.seoDescription}
        image={imageForSeo}
      />

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />

      <CampaignHeader />

      <main id="content">
        <CampaignLanding
          heading={data.contentfulCareersCampaign.heroHeading}
          subheading={data.contentfulCareersCampaign.heroTagline}
          button={data.contentfulCareersCampaign.heroButton}
          // image={data.contentfulCareersCampaign.heroImage}
        />
        <AboutYellowContent
          header={data.contentfulCareersCampaign.yellowSectionHeading}
          content={data.contentfulCareersCampaign.yellowSectionContent}
          className='campaign'
        />

        {data.contentfulCareersCampaign.latestRolesLists?.length > 0 ? (
          <LatestRoles roles={data.contentfulCareersCampaign.latestRolesLists} jobsLever={data.allLever.nodes} />
        ) : null}

        {data.contentfulCareersCampaign.middleRegion &&
                  <WidgetGroup region={data.contentfulCareersCampaign.middleRegion} />}

        {/* {showCentralImg && <CentralImage image={data.contentfulCareersCampaign.centralImage} />} */}
        
        
        {/* {data.contentfulCareersCampaign.bottomRegion &&
          <WidgetGroup region={data.contentfulCareersCampaign.bottomRegion} />} */}
        
        <QuoteBanner
          quoteContent={data.contentfulCareersCampaign.quoteContent}
          quoteAuthor={data.contentfulCareersCampaign.quoteAuthor}
          quoteImage={data.contentfulCareersCampaign.quoteImage}
        />

        {/* {data.contentfulCareersCampaign.awardsImages.length && (
          <HomePageLogos
            title={data.contentfulCareersCampaign.awardsSectionTitle}
            logos={data.contentfulCareersCampaign.awardsImages}
          />
        )} */}

        <CampaignFooterCTA
          heading={data.contentfulCareersCampaign.joinUsHeading}
          content={data.contentfulCareersCampaign.joinUsContent}
          buttons={data.contentfulCareersCampaign.joinUsButtons}
        />
      </main>
      <Footer />
    </PathContext.Provider>
  )
}

export default CampaignPage

export const query = graphql`
  query($id: String!) {
    allLever(sort: { fields: createdAt, order: DESC }) {
      nodes {
        ...JobPosting
      }
    }
    contentfulCareersCampaign(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      heroTagline
      heroHeading
      showNavbar
      heroButton {
        ...ContentfulLink
      }
      ogImage {
        file {
          url
        }
      }
      heroImage {
        fluid: gatsbyImageData(width: 2048 aspectRatio: 2.3 quality: 100 formats: [AUTO, WEBP])       
        title
      }

      yellowSectionHeading
      yellowSectionContent {
        ...RichTextField
      }

      quoteContent {
        ...RichTextField
      }
      quoteAuthor
      quoteImage {
        fluid: gatsbyImageData(width: 737 height: 1050 quality: 100 formats: [AUTO, WEBP])       
        title
      }

      centralImage {
        fluid: gatsbyImageData(width: 2048 height: 1500 quality: 100 formats: [AUTO, WEBP])        
        title
      }

      awardsSectionTitle
      awardsImages {
        title
        file {
          url
        }
      }
      latestRolesLists {
        title
        teamName
        image {
          fixed: gatsbyImageData(layout: FIXED width: 434 height: 591 quality: 100 formats: [AUTO, WEBP])          
          title
        }
        button {
          ...ContentfulLink
        }
        content {
          ...RichTextField
        }
        searchParam
      }
      joinUsHeading
      joinUsContent {
        ...RichTextField
      }
      joinUsButtons {
        ...ContentfulLink
      }

      bottomRegion {
        ...ComponentCTA
      }
      middleRegion {
        ...ComponentCTA
      }
    }
  }
`
