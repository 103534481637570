import React from "react"
import RichTextRenderer from "../../elements/RichTextRenderer"
import "./style.scss"

interface IAboutYellowContent {
  header: string
  content: object
  image?: { fluid: any; title?: string }
  className?: string
}

const AboutYellowContent: React.FC<IAboutYellowContent> = ({ header, content, image, className='' }) => {
  return (
    <div className={`blue-content-team ${className}`}>
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-11">
            <h2>{header}</h2>
          </div>
          <div className="col-lg-11">{content && <RichTextRenderer json={content} />}</div>
        </div>
      </div>
      <div className="imageContainer">
        {image && <img src={image?.fluid?.images?.fallback?.src} alt={image.title || ""} className="img-fluid" />}
      </div>
    </div>
  )
}

export default AboutYellowContent
