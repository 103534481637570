import RichTextRenderer from "../../../elements/RichTextRenderer";
import CTA from "../../../elements/CTA";
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import "./style.scss"

const WidgetRewards: React.FC = (props) => {
  let data = props;
  if (Object.keys(data).length === 0) {
    data = useStaticQuery(graphql`
    query WidgetRewardsQuery {
      contentfulCareersComponentCta(title: { eq: "Careers > Rewards Widget - CTA" }) {
        heading
        subheading
        content {
          ...RichTextField
        }
        images {
          fixed: gatsbyImageData(layout: FIXED, width: 84, quality: 100, formats: [AUTO, WEBP])
          
          title
          description
          file {
            url
            contentType
          }
        }
        button {
          ...ContentfulLink
        }
      }
    }
  `).contentfulCareersComponentCta
  }

  return (
    <div className="perks">
      <div className="container">
        {/* <span className="side-heading">{data.subheading}</span> */}
        <div className="row">
          <div className="col-md-11 col-lg-9 col-xxl-12 perks-intro">
            <span className="heading">{data.subheading}</span>
            <h2>{data.heading}</h2>
            <RichTextRenderer json={data.content} />
          </div>
        </div>
        <div className="row ">
          {data.images.map((image, index) => (
            <div className={`col-6 col-md-4 perk-item fade-in-up`}
              key={`img-${index}`}>
              <img srcSet={image?.fixed?.images?.fallback?.srcSet || image.file.url}
                alt={image.title || ""}
                className="img-fluid" />
              <h3>{image.title}</h3>
              <p>{image.description}</p>
            </div>
          ))}
        </div>
        {data.button && (
          <div className="row">
            <div className="col-md-11">
              <CTA {...data.button} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default WidgetRewards;
