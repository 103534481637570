import { graphql, useStaticQuery } from "gatsby"
import RichTextRenderer from "../../../elements/RichTextRenderer"
import PlaySVG from "../../SVGs/play.inline.svg"
import CTA from "../../../elements/CTA"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ModalVideo from "../../../elements/ModalVideo"

import "./style.scss"

interface IWidgetCultureProps {
  heading: string
  subheading: string
  className?: string
  video?: {
    brightcoveVideoId: string
  }
  images?: any
  content?: any
  button?: any
}

const WidgetCulture: React.FC<IWidgetCultureProps> = props => {
  const [modalShow, setModalShow] = React.useState<boolean>(false)

  const openModal = (event: any) => {
    event.preventDefault()
    setModalShow(true)
  }

  const hideModal = () => {
    setModalShow(false)
  }

  const className = props?.className ?? ""

  let data = props
  if (Object.keys(data).length === 0) {
    data = useStaticQuery(graphql`
      query WidgetCultureQuery {
        contentfulCareersComponentCta(title: { eq: "Careers > Culture Widget - CTA" }) {
          heading
          subheading
          content {
            ...RichTextField
          }
          video {
            brightcoveVideoId
          }
          images {
            fluid: gatsbyImageData(quality: 100, formats: [AUTO, WEBP])
            title
          }
          button {
            ...ContentfulLink
          }
        }
      }
    `).contentfulCareersComponentCta
  }

  return (
    <section className={`image-block ${className}`}>
      <div className="container position-relative">
        <div className="row flex-row-reverse">
          <div className="col-lg-6">
            {data.video?.brightcoveVideoId ? (
              <React.Fragment>
                <a className="js-video" href="#" onClick={openModal}>
                  <img
                    srcSet={data?.images[0]?.fluid?.images?.fallback?.srcSet}
                    alt={data.images[0].title || ""}
                    className="img-fluid"
                  />
                  <span
                    className="play-icon"
                    style={{ top: "50%", left: "50%", transform: "translate3d(-50%, -50%, 0)" }}
                  >
                    <PlaySVG className="icon-sprite" />
                    <div className="sr-only">Play video</div>
                  </span>
                </a>
                <ModalVideo show={modalShow} onHide={hideModal} video={data.video.brightcoveVideoId} />
              </React.Fragment>
            ) : (
              <GatsbyImage image={data.images[0].fluid} alt={data.images[0].title || ""} className="img-fluid" />
            )}
          </div>
          <div className="col-lg-6">
            <h3 className="heading">{data.subheading}</h3>
            <h2 className="culture-widget-heading">{data.heading}</h2>
            <RichTextRenderer json={data.content} />
            {data.button && <CTA {...data.button} />}
          </div>
        </div>
      </div>
    </section>
  )
}

export default WidgetCulture
