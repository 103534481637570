import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import yellowWave from "../../images/yellow-wave.jpg"
import "./style.scss"
import RichTextRenderer from "../../elements/RichTextRenderer"

interface QuoteBanner {
  primary?: boolean
  quoteContent: string
  quoteAuthor: string
  quoteImage?: {
    fluid: any
    title?: string
  }
  className?: string
}

const QuoteBanner: React.FC<QuoteBanner> = ({
  quoteContent,
  quoteAuthor,
  quoteImage,
  primary,
  className='',
}) => {
  return (
    <section className={`blockquote-banner${primary === true ? " bg-primary" : ""} ${className}`}>
      <img src={yellowWave} alt="yellowWave" className="yellowWave"/>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8 col-lg-6 fade-in-up">
            <blockquote className="blockquote">
              {quoteContent ? (
                typeof quoteContent === "string" ? (
                  <p className="mb-0">{quoteContent}</p>
                ) : (
                  <RichTextRenderer json={quoteContent} />
                )
              ) : null}

              <footer className="blockquote-footer">
                <cite title="Anon">{quoteAuthor}</cite>
              </footer>
            </blockquote>
          </div>
          <div className="col-md-4 col-lg-6 align-self-end fade-in">
            {quoteImage && (
              <GatsbyImage image={quoteImage.fluid} alt={quoteImage.title || ""} className="img-fluid" />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default QuoteBanner
