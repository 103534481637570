import React from "react"
import CTA from "../../../elements/CTA"
import "../../../layouts/Primary/styles/skin/templates/_campaign.scss"
import RichTextRenderer from "../../../elements/RichTextRenderer"
import "./style.scss"

interface ICampaignFooterCTA {
  heading: string
  content: any
  buttons: Array<any>
}

const CampaignFooterCTA: React.FC<ICampaignFooterCTA> = ({ heading, content, buttons }) => {
  return (
    <section className="footer-section">
    <div className="footer-cta">
      <div className="container">
        <div className="col-lg-10 offset-xl-1">
          <h2 className="h1">{heading}</h2>
          <RichTextRenderer json={content} noContainer />
          <div className="footer-cta-btns">
            {buttons && buttons.length > 0 && buttons.map(button => <CTA {...button} />)}
          </div>
        </div>
      </div>
    </div>
    <div className="rightBlue"></div>
    </section>
  )
}

export default CampaignFooterCTA
