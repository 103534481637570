import React from "react"
import "../../../layouts/Primary/styles/skin/templates/_campaign.scss"
// @ts-ignore
import LogoSVG from "../../SVGs/logo.inline.svg"

interface ICampaignHeader {}

const CampaignHeader: React.FC<ICampaignHeader> = () => {
  return (
    <header className="header header-simple" id="header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <a className="col-6 col-lg-2 pl-lg-4" href="/">
            <LogoSVG className="img-fluid" width="177" height="28" />
          </a>
        </div>
      </div>
    </header>
  )
}

export default CampaignHeader
