import React from "react";
import "./style.scss"
import RichTextRenderer from "../../../elements/RichTextRenderer";
import { GatsbyImage } from "gatsby-plugin-image";
import CTA from "../../../elements/CTA";

interface IWidgetCorePrinciples {
  subheading: string
  heading: string
  content: object
  images: Array<{
    fixed: any
    fluid: any
    title?: string
  }>
  button?: any
}

const WidgetCorePrinciples: React.FC<IWidgetCorePrinciples> = ({ subheading, heading, content, images, button }) => {
  return (
    <section className={`image-block ${subheading.toLowerCase() === 'values' ? 'image-block-light' : ''}`}>
      <div className="container position-relative">
        <div className="row">
          <div className="col-lg-6">
            <GatsbyImage className="img-fluid" image={images[0].fluid} alt={images[0].title || ""} />
          </div>
          <div className="col-lg-6">
            <h3 className="heading">{subheading}</h3>
            <h2>{heading}</h2>
            <RichTextRenderer json={content} />
            {button && <CTA {...button} />}
          </div>
        </div>
      </div>
    </section>
  )
}

export default WidgetCorePrinciples;
