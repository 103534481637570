import classNames from "classnames"
import React from "react"
import ArrowLeftSVG from "../../components/SVGs/arrow-left.inline.svg"
import ArrowRightSVG from "../../components/SVGs/arrow-right.inline.svg"
import { scrollToElementById } from "../../utils/helpers"

const Pagination: React.FC<{ paginationLength: number; page: number; onPageChange: Function; className?: string | null }> = ({
  paginationLength,
  page,
  onPageChange,
  className = "",
}) => {
  const generatePagination = () => {
    if (paginationLength > 3) {
      if (page > paginationLength - 2) {
        return [paginationLength - 3, paginationLength - 2, paginationLength - 1]
      }
      if (page < 2) {
        return [0, 1, 2]
      }

      return [page - 1, page, page + 1]
    }

    return Array.from(Array(paginationLength).keys())
  }

  const handlePageChange = (index: number) => {
    onPageChange(index)
    scrollToElementById("blog-listing")
  }

  return paginationLength > 1 ? (
    <nav aria-label="Page navigation">
      <ul className={classNames("pagination", className)}>
        <li className="page-item previous">
          <button className="page-link" aria-label="Previous" onClick={() => handlePageChange(page - 1)}>
            <ArrowLeftSVG className="icon-sprite" />
            <span className="sr-only">Previous</span>
          </button>
        </li>
        {generatePagination().map((index: number) => (
          <li className={`page-item page-number ${index === page ? "active" : ""}`} key={`pagination-${index}`}>
            <button className="page-link" onClick={() => handlePageChange(index)}>
              {index + 1}
            </button>
          </li>
        ))}
        <li className="page-item next">
          <button className="page-link shadow-none" aria-label="Next" onClick={() => handlePageChange(page + 1)}>
            <ArrowRightSVG className="icon-sprite" />
            <span className="sr-only">Next</span>
          </button>
        </li>
      </ul>
    </nav>
  ) : null
}

export default Pagination
