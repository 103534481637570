import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { toggleJob } from "../../state/app"

interface IJobObj {
  id: string
  text: string
  categories: {
    location: string
  }
}

interface Job {
  job: IJobObj
  title: string
  location: string
  savedJobs: any
  toggleJob: Function
  disableSave?: boolean
  recentlySaved?: boolean
}

const Job: React.FC<Job> = ({ job, savedJobs, toggleJob, disableSave, recentlySaved = false }) => {
  const [isSaved, setIsSaved] = useState(Boolean(savedJobs[job.id]))

  const handleOnClick = (job: IJobObj) => {
    try {
      toggleJob(job)
      setIsSaved(!isSaved)

      let eventName = isSaved ? "job_unsave" : "job_save"
      // @ts-ignore
      dataLayer.push({
        event: eventName,
        job_info: {
          job_title: job?.text,
          job_id: job?.id,
        },
      })
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  useEffect(() => setIsSaved(Boolean(savedJobs[job.id])), [job, savedJobs])
  const addDataLayerEvent = () => {
    // @ts-ignore
    dataLayer.push({
      event: "job_view",
      job_info: {
        job_title: job?.text,
        job_id: job?.id,
      },
    })
    if (recentlySaved) {
      // @ts-ignore
      dataLayer.push({
        event: "job_view_saved",
        job_info: {
          job_title: job?.text,
          job_id: job?.id,
        },
      })
    }
  }

  return (
    <div className="card card-job js-job">
      <div className="card-body">
        <h2 className="card-title">
          <Link to={`/job/${job.id}`} onClick={() => addDataLayerEvent()}>
            {job.text}
          </Link>
        </h2>
        <ul className="list-inline">
          <li className="list-inline-item">{job.categories.location}</li>
        </ul>
      </div>
      {disableSave ? null : (
        <div className="card-footer">
          <a
            className={`${isSaved ? "btn-remove-job" : "btn-add-job"}`}
            title="Save this to My Jobs"
            onClick={() => handleOnClick(job)}
          >
            <span className="sr-only">Save Job</span>
            <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 14.616">
              <path
                className="save-icon"
                data-name="Icon awesome-bookmark"
                d="M0,12V.125H9V12L4.5,9.375Z"
                transform="translate(1 0.875)"
                fill="none"
                stroke="#26d1a0"
                strokeWidth="2"
              />
            </svg>
          </a>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  savedJobs: state.app.savedJobs,
})

const mapDispatchToProps = dispatch => ({
  toggleJob: job => dispatch(toggleJob(job)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Job)
