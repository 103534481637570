import React, { useEffect, useState } from "react"
import { useQueryParam, StringParam } from "use-query-params"
import JobPosting from "../job-single"
import Pagination from "../../elements/Pagination"
import "./style.scss"

interface IJobs {
  leverJobs: Array<any>
  showPagination?: boolean
  jobListingPage?: boolean
  disableSave?: boolean
  className?: string | null
}

const JobsListingComponent: React.FC<IJobs> = ({
  leverJobs,
  showPagination,
  jobListingPage,
  disableSave,
  className = null,
}) => {
  const chunk = (arr: Array<any>, size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))

  const perPage = 6
  const showFeaturedJobsTitle = className === "show-featured-title"

  const [jobs, setJobs] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(0)
  const [paginationLength, setPaginationLength] = useState(0)
  const [fallbackMessage, setFallbackMessage] = useState("")

  // Query string filters
  const [keyword] = useQueryParam("keyword", StringParam)
  const [team] = useQueryParam("team", StringParam)
  const [location] = useQueryParam("location", StringParam)
  const [workplaceType] = useQueryParam("workplaceType", StringParam)

  useEffect(() => updateJobs(), [])

  useEffect(() => {
    setPage(0)
    updateJobs()
  }, [team, keyword, location, workplaceType])

  useEffect(() => {
    updateJobs()
  }, [leverJobs, page])

  const updateJobs = () => {
    let jobs = filter(leverJobs)
    let message = ""

    if (jobs.length === 0 && jobListingPage) {
      if (keyword) {
        if (team && location && workplaceType) {
          const resultsTeamLocationWorkplaceType = filterFallback(team, location, workplaceType)

          if (resultsTeamLocationWorkplaceType.length !== 0) {
            jobs = resultsTeamLocationWorkplaceType
            message = `Currently, no jobs match your criteria. Check out our other job openings for selected team${checkFilterPlural(
              team
            )} and/or location${checkFilterPlural(location)} and/or location type${workplaceType}.`
          } else {
            jobs = filterFallback(false, location, false)
            message = `Currently, no jobs match your criteria. Check out our other job openings for selected team${checkFilterPlural(
              team
            )} and/or location${checkFilterPlural(location)} and/or location type${workplaceType}.`
          }
        } else if (team) {
          const resultsTeam = filterFallback(team, false, false)
          const resultsRemote = resultsTeam.filter(
            job => job.text.toLowerCase().includes("remote") || job.categories.location.toLowerCase().includes("remote")
          )

          if (resultsRemote.length !== 0) {
            jobs = resultsRemote
            message = `Currently, no jobs match your criteria. Check out our other job openings remote options for the selected team${checkFilterPlural(
              team
            )}.`
          } else {
            jobs = resultsTeam
            message = `Currently, no jobs match your criteria. Check out our other similar job openings for selected team${checkFilterPlural(
              team
            )}.`
          }
        } else if (location) {
          jobs = filterFallback(false, location, false)
          message = `Currently, no jobs match your criteria. Check out our other similar job openings for selected location${checkFilterPlural(
            location
          )}.`
        } else {
          jobs = leverJobs.filter(job => job.additionalPlain.indexOf("#featured") !== -1)
          message = "Currently, no jobs match your criteria. Check out our featured jobs."
        }
      } else if (workplaceType) {
        jobs = filterFallback(false, false, workplaceType)
        message = `Currently, no jobs match your criteria. Check out our other similar job openings for selected location type.`
      } else {
        jobs = filterFallback(false, location, false)
        message = `Currently, no jobs match your criteria. Check out our other similar job openings for selected location${checkFilterPlural(
          location
        )}.`
      }
    }

    const chunkedJobs = chunk(jobs, perPage)

    setPageCount(jobs.length)
    setPaginationLength(chunkedJobs.length)
    setJobs(chunkedJobs[page] || [])
    setFallbackMessage(message)
  }

  const checkFilterPlural = (filter: any) => {
    return filter?.toLowerCase().split("+").length > 1 ? "s" : ""
  }

  const filter = (leverJobs: Array<any>) =>
    leverJobs.filter((job: any) => {
      if (location) {
        const locations = location.toLowerCase().split("+")
        const hasLocationMatch = locations.includes(job.categories.location.toLowerCase())

        if (!hasLocationMatch) {
          return false
        }
      }
      if (team) {
        const teams = team.toLowerCase().split("+")
        const hasTeamMatch = teams.includes(job.categories.team.toLowerCase())

        if (!hasTeamMatch) {
          return false
        }
      }
      if (keyword) {
        const keywordClean = keyword
          .toLowerCase()
          .replace(/[^a-z0-9 ]/g, " ")
          .trim()
        const keywordWords = keywordClean.split(" ")
        const titleClean = job.text
          .toLowerCase()
          .replace(/[^a-z0-9 ]/g, " ")
          .trim()
        const titleWords = titleClean.split(" ")
        const inTitle = keywordWords.every(word => titleWords.includes(word))

        if (!inTitle) {
          const descriptionClean = job.descriptionPlain
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, " ")
            .trim()
          const descriptionWords = descriptionClean.split(" ")
          const inDescription = keywordWords.every(word => descriptionWords.includes(word))

          if (!inDescription) {
            return false
          }
        }
      }
      if (workplaceType) {
        const workplaceTypes = workplaceType?.toLowerCase().split("+")
        const hasWorkplaceTypeMatch = workplaceTypes?.includes(job?.workplaceType?.toLowerCase())

        if (!hasWorkplaceTypeMatch) {
          return false
        }
      }
      return true
    })

  const filterFallback = (teamValue: any, locationValue: any, workplaceTypeValue: any) =>
    leverJobs.filter((job: any) => {
      if (locationValue) {
        const locations = locationValue.toLowerCase().split("+")
        const hasLocationMatch = locations.includes(job.categories.location.toLowerCase())

        if (!hasLocationMatch) {
          return false
        }
      }
      if (teamValue) {
        const teams = teamValue.toLowerCase().split("+")
        const hasTeamMatch = teams.includes(job.categories.team.toLowerCase())

        if (!hasTeamMatch) {
          return false
        }
      }
      if (workplaceTypeValue) {
        const workplaceType = workplaceTypeValue.toLowerCase().split("+")
        const hasWorkplaceTypeMatch = workplaceType?.includes(job?.workplaceType.toLowerCase())

        if (!hasWorkplaceTypeMatch) {
          return false
        }
      }
      return true
    })

  const handlePageChange = (pageChange: number) => {
    if (pageChange < 0 || pageChange > paginationLength - 1) {
      return
    }
    setPage(pageChange)
  }

  const showingJobsCount = (paginationLength, page, pageCount) => {
    const jobCount = (page + 1) * perPage
    if (paginationLength === page + 1) {
      return jobCount > pageCount ? pageCount : jobCount
    }

    return jobCount
  }

  return (
    <>
      {fallbackMessage !== "" ? (
        <div className="fallback-wrapper">
          <p className="text-center h4 fallback-message">{fallbackMessage}</p>
        </div>
      ) : null}
      {showFeaturedJobsTitle && <p className="job-listing-featured-jobs">Featured Jobs</p>}
      {showPagination && jobs !== null && pageCount > jobs.length && showFeaturedJobsTitle && (
        <div className="job-listing-job-count">
          <p>
            Showing jobs{" "}
            <strong>
              {page * perPage + 1} to {showingJobsCount(paginationLength, page, pageCount)}
            </strong>{" "}
            of {pageCount}
          </p>
        </div>
      )}

      {jobs !== null && (
        <>
          {jobs.length > 0 ? (
            <div className="job-listing-jobs-container">
              {jobs.map((posting: any, i: number) => (
                <JobPosting job={posting} key={i} disableSave={disableSave} />
              ))}
            </div>
          ) : (
            <p className="text-center h4">Sorry, no jobs match your criteria</p>
          )}
        </>
      )}

      {showPagination && <Pagination paginationLength={paginationLength} page={page} onPageChange={handlePageChange} className={className}/>}
    </>
  )
}

export default JobsListingComponent
