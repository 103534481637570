import React from "react"
import { graphql } from "gatsby"
import WidgetRewards from "./rewards"
import WidgetCulture from "./culture"
import WidgetCookie from "./cookie"
import WidgetCorePrinciples from "./core-principles"

interface IWidgetProps {
  title: string
  variation: string
  heading: string
  subheading: string
  content: any
  images: Array<any>
  button?: any
  orientation?: string
  className?: string
}

const Widget: React.FC<IWidgetProps> = props => {
  switch (props.variation.toLowerCase()) {
    case "rewards":
      return <WidgetRewards {...props} />
    case "culture":
      return <WidgetCulture className={props?.className} {...props} />
    case "cookie":
      return <WidgetCookie {...props} />
    case "core principles":
      return <WidgetCorePrinciples {...props} />

    default:
      return (
        <section>
          <div>
            <strong>COMPONENT:</strong> Widgets
          </div>
          <div>
            <strong>TITLE:</strong> {props.title}
          </div>
          <div>
            <strong>VARIATION:</strong> {props.variation}
          </div>
        </section>
      )
  }
}

export default Widget

export const query = graphql`
  fragment ComponentCTAFields on ContentfulCareersComponentCta {
    title
    heading
    variation
    subheading
    video {
      brightcoveVideoId
    }
    images {
      title
      description
      fluid: gatsbyImageData(layout: FULL_WIDTH placeholder: NONE width: 1920 quality: 100 formats: [AUTO, WEBP])
      
      fixed: 
      gatsbyImageData(
        layout: FIXED
        width: 201
        quality: 100
        formats: [AUTO, WEBP]
        )
      
      file {
        url
      }
    }
    button {
      ...ContentfulLink
    }
    orientation
  }
  fragment ComponentCTA on ContentfulCareersComponentCta {
    ...ComponentCTAFields
    content {
      ...RichTextField
    }
  }
`
