import React, { useState } from "react"
import RichTextRenderer from "../../../elements/RichTextRenderer"
import JobListingComponent from "../../../components/job-listing"
import PlaySVG from "../../SVGs/play.inline.svg"
import ModalVideo from "../../../elements/ModalVideo"
import "./style.scss"

interface ILatestRoles {
  roles: {
    title: string
    teamName: string
    image: {
      fixed: any
      title: string
    }
    button: any
    content: any
    searchParam: string
  }[]
  jobsLever: Array<any>
}

const LatestRoles: React.FC<ILatestRoles> = ({ roles, jobsLever }) => {
  const [activeModalIndex, setActiveModalIndex] = useState(null)

  const openModal = (modalIndex: any, e: any) => {
    e.preventDefault()
    setActiveModalIndex(modalIndex)
  }

  const hideModal = () => {
    setActiveModalIndex(null)
  }

  return (
    <section className="campaign-roles" id="campaign-roles">
      {roles.map((role, index) => {
        const roleJobs = jobsLever.filter(posting => posting.additionalPlain.indexOf(role.searchParam) !== -1)
        return (
          <div className="role-single" key={index}>
            <svg
              className="latest-jobs-line"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 180.589 42.307"
              height="42.307"
              width="180.589"
            >
              <path
                strokeDasharray="7 8"
                strokeWidth="3"
                strokeMiterlimit="10"
                stroke="#fff"
                fill="none"
                d="M180.589 40.807H70.802L72.34 1.5H0"
                data-name="Path 215"
              ></path>
              <path
                className="maze-line"
                strokeWidth="5"
                strokeMiterlimit="10"
                stroke={index % 2 === 0 ? "#10191d" : "#0075A1"}
                fill="none"
                d="M180.589 40.807H70.802L72.34 1.5H0"
                data-name="Path 215"
                strokeDasharray="221.46408081054688"
                style={{ strokeDashoffset: "221.464px" }}
              ></path>
            </svg>
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-5">
                  <div className="role-title">{role.title}</div>
                  <div className="role-sticky">
                    <h3 className="role-team">{role.teamName}</h3>
                    <div className="role-asset">
                      {role.button && role.button?.url ? (
                        <>
                          <a className="js-video" href="#" onClick={e => openModal(index, e)}>
                            <img
                              src={role.image?.fixed?.src || "#"}
                              alt={role.image?.title || ""}
                              className="img-fluid hero-image-angle"
                            />
                            <span className="play-icon">
                              <PlaySVG className="icon-sprite" />
                              <div className="sr-only">{role.button?.label}</div>
                            </span>
                          </a>
                          <ModalVideo show={activeModalIndex === index} onHide={hideModal} video={role.button?.url} />
                        </>
                      ) : (
                        <img
                          src={role.image?.fixed?.src || "#"}
                          alt={role.image?.title || ""}
                          className="img-fluid hero-image-angle"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 role-right-column">
                  {role.content && <RichTextRenderer json={role.content} />}
                  {roleJobs.length > 0 ? (
                    <JobListingComponent leverJobs={roleJobs} showPagination disableSave />
                  ) : (
                    <p className="roles-message">Sorry, there are no open roles.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default LatestRoles
